<template>
  <sell-investment-shares-page
    :isDocsAllright="isDocumentsFilledOut"
    :documents-required="documentsRequired"
    :user="getUSER"
    :timer="timer"
    :show-checking="showChecking"
    :accounts-list="getBANK_ACCOUNTS"
    :fund-name="getFundDetales.name"
    :fund-id="getFundDetales.id"
    :fund-price="getFundDetales.price"
    :fund-ls-number="getFundDetales.ls_number"
    :fund-ls-request-number="getFundDetales.ls_request_number"
    :fund-account-number="getFundDetales.bank_account_id"
    :fund-purchases="fundPurchases"
    :fund-shares="fundShares"
    @submit="onSibmit"
    @verified="onVerifiedCode"
    @sendAgain="onSibmit"
    @timer="timerIsOver"
    @dataChanged="hideSMS"
  />
</template>

<script>
import SellInvestmentSharesPage from "../../../components/pages/cabinet/Funds/SellInvestmentSharesPage";
import { mapGetters, mapActions } from "vuex";
import RWMSendingDocumentsMessageMixin from "../../../components/mixins/RWMSendingDocumentsMessageMixin";

export default {
  name: "SellInvestmentShares",
  components: { SellInvestmentSharesPage },
  mixins: [RWMSendingDocumentsMessageMixin],
  watch: {
    fondChanged() {
      if(this.$route.params.fundId == this.changedFondId) {
        this.GET_BANK_ACCOUNTS();
        this.GET_FUND_DETALES(this.$route.params.fundId);
      }
    }
  },
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("bankAccModule", ["getBANK_ACCOUNTS"]),
    ...mapGetters("FundsModule", ["getFundDetales"]),
    ...mapGetters("AutoUpdateModule", ["fondChanged", "changedFondId"]),
    fundShares() {
      return this.getFundDetales.userInvestShares?.share_count
    },
    fundPurchases() {
      return this.getFundDetales?.purchases
    },
    isDocumentsFilledOut() {
      // here we making reactive variable based on documents checking : true going to be able if documents in status approved
      return !!(
        this.getFundDetales?.anket_reg_person &&
        this.getFundDetales?.open_pers_account
      );
    },
    documentsRequired() {
      return this.getFundDetales.ankets?.length > 0
        ? this.getFundDetales.ankets?.length < 2
          ? this.getFundDetales.ankets[0].type_id === 4
            ? [
                {
                  id: 348,
                  name: "Заявление об открытии ЛС",
                  status: "",
                  status_id: 0,
                  type_id: 5,
                },
                ...this.getFundDetales.ankets,
              ]
            : this.getFundDetales.ankets[0].type_id === 5
            ? [
                {
                  name: "Анкета зарегистрированного ФЛ",
                  status: "",
                  status_id: 0,
                  type_id: 4,
                  id: 347,
                },
                ...this.getFundDetales.ankets,
              ]
            : []
          : this.getFundDetales.ankets
        : [
            {
              name: "Анкета зарегистрированного ФЛ",
              status: "",
              status_id: 0,
              type_id: 4,
              id: 347,
            },
            {
              id: 348,
              name: "Заявление об открытии ЛС",
              status: "",
              status_id: 0,
              type_id: 5,
            },
          ];
    },
  },
  data() {
    return {
      timer: false,
      showChecking: false,
    };
  },
  methods: {
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("bankAccModule", ["GET_BANK_ACCOUNTS"]),
    ...mapActions("FundsModule", ["GET_FUND_DETALES"]),
    ...mapActions("DocumentsModule", ["CREATE_DOCUMENT"]),

    hideSMS() {
      this.showChecking = false
    },
    onSibmit() {
      this.loading = true;
      this.REQUEST_SMS({ phone: this.getUSER.email, action: 'sell_shares' }).then((success) => {
        if (success) {
          this.showChecking = true;
          this.timer = true;
          this.loading = false;
        } else {
          this.$toast.error(`${this.getERROR}`);
        }
      });
    },
    onVerifiedCode(data) {
      let { code, form } = data;
      this.CONFIRM_SMS({ phone: this.getUSER.email, key: code }).then(
        (success) => {
          if (success) {
            this.CREATE_DOCUMENT({
              alias: "appl-redemption_invest_shares",
              data: form,
            }).then((responce) => {
              if (responce) {
                this.show_sending_documents_message();
                setTimeout(() => {
                  this.$router.push("/cabinet/investment/redemption");
                }, 300);
              }
            });
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    timerIsOver(timer) {
      this.timer = timer;
    },
  },
  mounted() {
    this.GET_BANK_ACCOUNTS();
    this.GET_FUND_DETALES(this.$route.params.fundId);
  },
};
</script>

<style scoped lang="scss"></style>
