<template>
    <div class="form">
        <h1>Погашение паев</h1>
        <div class="form__body">
            <redemption-requisition
                    :user="user"
                    :timer="timer"
                    :fund-name="fundName"
                    :show-checking="showChecking"
                    :share-cost="fundPrice"
                    :fund-account-number="fundLsNumber"
                    :accounts-list="accountsList"
                    :bank-account="fundAccountNumber"
                    :fund-id="fundId"
                    :fund-purchases="fundPurchases"
                    :fund-shares="fundShares"
                    @submit="onSibmit"
                    @verified="onVerifiedCode"
                    @sendAgain="sendAgain"
                    @timer="timerIsOver"
                    @dataChanged="$emit('dataChanged')"
            />
        </div>
    </div>
</template>

<script>
    import RedemptionRequisition from "./RedemtionRequisition";
    export default {
        name: "SellInvestmentSharesPage",
        components: {RedemptionRequisition},
        props: {
            timer: {
                type: Boolean,
                default: false
            },
            showChecking: {
                type: Boolean,
                default: false
            },
            isDocsAllright: {
                type: Boolean,
                required: true
            },
            documentsRequired: {
                type: Array,
                required: true
            },
            user: Object,
            accountsList: Array,
            fundName: String,
            fundId: [String, Number],
            fundPrice: [String, Number],
            fundLsNumber: [String, Number],
            fundLsRequestNumber: [String, Number],
            fundAccountNumber: [String, Number],
            fundPurchases: Array,
            fundShares: [String, Number]
        },
        methods: {
            onSibmit() {
                this.$emit('submit')
            },
            onVerifiedCode(code) {
                this.$emit('verified', code)
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
            timerIsOver(timer) {
                this.$emit('timer', timer)
            }
        }
    }
</script>

<style scoped lang="scss">
    .form {
        text-align: left;

        & h1 {
            margin-bottom: 32px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
        }
    }
</style>