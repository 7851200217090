<template>
    <section v-if="shareCost && shareCost!==null" class="purchase-requisition">
        <div class="row">
            <div class="col">
                <h3>Заявка на погашение инвестиционных паев физическими лицами</h3>
                <p class="anketa">{{fundName}}</p>
                <p class="anketa">под управлением ЗАО УК «РВМ Капитал»</p>
            </div>
            <div class="col-3">
                <p class="share-cost anketa"><span class="share-cost_label">Стоимость пая</span>{{ shareCost ? shareCost.toLocaleString() : '' }} р.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <rwm-text-field disabled width="100%" type="text" :value="fundAccountNumber" label="Номер лицевого счета" />
            </div>
        </div>
        <client-block :mname="user.userName.mname" :name="user.userName.name" :sname="user.userName.sname" :show-date="false"/>
        <passport-block :department="user.passport.department" :issue="user.passport.issue"
                        :issue-date="user.passport.issueDate"
                        :number="user.passport.number"/>
        <div class="row">
            <div class="col-9">
                <p class="anketa" style="width: 100%">
                    Прошу погасить принадлежащие мне инвестиционные паи в количестве:
                </p>
            </div>
            <div class="col-3">
                <div style="display: flex; align-items: flex-end">
                    <rwm-text-field
                            v-model="resultAmount"
                            label="Укажите количество шт."
                            width="100%"
                            :error="errors.localAmount"
                            @blur="onchange(), validate('localAmount')"
                            style="flex: 1 1 auto"
                    />
                    <span class="anketa" style="padding-left: 10px; flex: 0">штук.</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <rwm-select
                        placeholder="Банковские реквизиты"
                        :default-value="defaultAccount"
                        :list="accountsList ? accountsList : []"
                        @selected="onSelect"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <rwm-checkbox
                        label="Являюсь налоговым резидентом РФ"
                        v-model="taxResident"
                        @click="onchange()"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <rwm-checkbox
                        label="Уведомление об операции прошу направить в Управляющую компанию"
                        v-model="notificationToMC"
                        @click="onchange()"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <rwm-checkbox
                        label="С правилами фонда ознакомлен"
                        v-model="agree"
                        :error="errors.agree"
                        @click="onchange()"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="anketa">Настоящая заявка носит безотзывный характер</p>
            </div>
        </div>



        <div class="row">
            <div class="col-3">
                <rwm-button width="100%" @click="submit" :disabled="isRedy">Подписать</rwm-button>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <rwm-sms-checking v-model="_timer" :isShow="showChecking" :time="60" @sendAgain="sendAgain()"
                                  @verified="codeIsVerified"/>
            </div>
        </div>
        <rwm-footnote :footnotes="footnotes" />
    </section>
    <p v-else class="anketa">Не указана стоимость пая</p>
</template>

<script>
    import ClientBlock from "../documents/forms/blocks/ClientBlock";
    import PassportBlock from "../documents/forms/blocks/PassportBlock";
    import RwmSmsChecking from "../../../RWMSMSChecking/RwmSmsChecking";
    import RwmButton from "../../../RWMButton/RwmButton";
    import RwmTextField from "../../../RWMTextField/RwmTextField";
    import moment from "moment";
    import RwmSelect from "../../../RWMSelect/RwmSelect";
    import RwmFootnote from "../../../RWMFootnote/RwmFootnote";
    import RwmCheckbox from "../../../RWMCheckbox/RwmCheckbox";
    export default {
        name: "RedemptionRequisition",
        components: {
            RwmCheckbox,
            RwmFootnote,
            RwmSelect,
            RwmTextField, RwmButton, RwmSmsChecking, PassportBlock, ClientBlock},
        props: {
            user: Object,
            timer: {
                type: Boolean,
                default: false
            },
            showChecking: {
                type: Boolean,
                default: false
            },
            fundAccountNumber: [Number, String],
            shareCost: [Number, String],
            accountsList: Array,
            bankAccount: [Number, String],
            fundId: [Number, String],
            fundName: String,
            fundPurchases: Array,
            fundShares: [String, Number]
        },
        computed: {
            defaultAccount() {
                return this.accountsList.find(el => el.id === this.bankAccount) ? this.accountsList.find(el => el.id === this.bankAccount).name : this.accountsList[0]?.name
            },
            defaultAccountId() {
                return this.accountsList.find(el => el.id === this.bankAccount) ? this.accountsList.find(el => el.id === this.bankAccount).id : this.accountsList[0]?.id
            },
            amount() {
                return this.fundShares
            },
            resultAmount: {
                get() {
                    return this.localAmount
                },
                set(val) {
                    val = val.replace(",", ".")
                    parseFloat(val) > 0 && parseFloat(val) <= parseFloat(this.amount) ? this.localAmount = parseFloat(val) : this.localAmount = parseFloat(this.amount)
                }
            },
            _timer: {
                get() {
                    return this.timer
                },
                set(val) {
                    this.$emit('timer', val)
                }
            },
        },
        data() {
            return {
                isRedy: false,
                agree: false,
                taxResident: false,
                notificationToMC: true,
                localBankAccount: null,
                footnotes: [
                    "Заполняется для заявки на приобретение инвестиционных паев, подаваемой в связи с осуществлением преимущественного права на приобретение дополнительных инвестиционных паев.",
                    "Не заполняется для заявки на приобретение инвестиционных паев, подаваемой в связи с осуществлением преимущественного права на приобретение дополнительных инвестиционных паев."
                ],
                errors: {
                    localAmount: false,
                    agree: false
                },
                localAmount: null
            }
        },
        methods: {
            onSelect(val) {
                this.localBankAccount = val
            },
            onchange() {
                this.isRedy = false
                this.$emit('dataChanged')
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
            submit() {
                if (this.validate("all")) {
                    this.$emit('submit')
                }
                this.isRedy = true
            },
            validate(param) {
                if (param === 'all') {
                    if (this.validate('localAmount') && this.validate('agree')) {
                        return true
                    } else return false
                } else {
                    if (param === 'localAmount') {
                        if (this[param]) {
                            if ((this[param].toString().split('.')[1] || '').length > 5) {
                                this.$toast.error("Максимальная точность 5 знаков после запятой")
                                let cut = []
                                cut.push(this[param].toString().split('.')[0])
                                cut.push(this[param].toString().split('.')[1].substring(0, 5))
                                this[param] = parseFloat(cut.join("."))
                            }
                        }

                        this.errors[param] = parseFloat(this[param]) <= 0
                        if (parseFloat(this[param]) <= 0) {
                            this.$toast.error('Введите необходимое количество паев')
                        }
                        return parseFloat(this[param]) > 0
                    }
                    if (param === 'agree') {
                        this.errors[param] = !(this[param])
                        if (!this[param]) {
                            this.$toast.error('Ознакомьтесь с правилами фонда')
                        }
                        return this[param]
                    }
                }
            },
            codeIsVerified(code) {
                let model = {
                    ls_number: this.fundAccountNumber,                                // Номер лицевого счета
                    bank_account_id: this.localBankAccount ? this.localBankAccount.id : this.defaultAccountId,              // ID банковских реквизитов
                    amount: this.resultAmount,                     // Запрашиваемое количество
                    rf_tax_resident: this.taxResident,                          // Являюсь налоговым резидентом РФ
                    sending_notification_email: this.notificationToMC,               // Уведомление об операции направить на электронную почту
                    is_acquainted_rules: this.agree,                      // С правилами фонда ознакомлен
                    fond_id: this.fundId,
                    date_sign: moment(new Date()).format("X"),
                    signed: true
                }
                this.$emit('verified', {code: code, form: model})
            },
        }
    }
</script>

<style scoped lang="scss">
    .purchase-requisition {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
    }
    .share-cost {
        position: relative;
        color: $RWM-grey;
        @media screen and (min-width: 1280px) {

        }
        &_label {
            color: $RWM-green;
            margin-right: 20px;
            @media screen and (min-width: 1280px) {
                position: absolute;
                bottom: 100%;
                font-size: 16px;
                margin-right: 0;
            }
        }
    }
    .with-question {
        display: flex;
        align-items: center;
        column-gap: 12px
    }
</style>
